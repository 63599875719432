/* global Blockly */
/* global Util */
import _ from 'underscore';

/*
NOTE: This block should not be used outside the context of an ajax call.
The JS it returns depends on the namespace established for the
error or success callback of an ajax call.
 Thus this function always returns null (should never appear in the toolbox)
*/
const plusImage = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-add 02-SNAP%3C/title%3E%3Cg id='icon-add-02-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M14,2 C20.627417,2 26,7.372583 26,14 C26,20.627417 20.627417,26 14,26 C7.372583,26 2,20.627417 2,14 C2,7.372583 7.372583,2 14,2 Z M14,3 C7.92486775,3 3,7.92486775 3,14 C3,20.0751322 7.92486775,25 14,25 C20.0751322,25 25,20.0751322 25,14 C25,7.92486775 20.0751322,3 14,3 Z' id='Combined-Shape' fill='%23FFFFFF'%3E%3C/path%3E%3Crect id='Rectangle' fill='%23FFFFFF' x='8' y='13' width='12' height='2' rx='0.75'%3E%3C/rect%3E%3Crect id='Rectangle-Copy' fill='%23FFFFFF' transform='translate(14.000000, 14.000000) rotate(90.000000) translate(-14.000000, -14.000000) ' x='8' y='13' width='12' height='2' rx='0.75'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E";
const minusImage = "data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8'%3F%3E%3Csvg width='28px' height='28px' viewBox='0 0 28 28' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Ctitle%3Eicon-remove 02-SNAP 2%3C/title%3E%3Cg id='icon-remove-02-SNAP' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cpath d='M14,2 C20.627417,2 26,7.372583 26,14 C26,20.627417 20.627417,26 14,26 C7.372583,26 2,20.627417 2,14 C2,7.372583 7.372583,2 14,2 Z M14,3 C7.92486775,3 3,7.92486775 3,14 C3,20.0751322 7.92486775,25 14,25 C20.0751322,25 25,20.0751322 25,14 C25,7.92486775 20.0751322,3 14,3 Z' id='Combined-Shape' fill='%23FFFFFF'%3E%3C/path%3E%3Crect id='Rectangle' fill='%23FFFFFF' x='8' y='12.5' width='12' height='3' rx='1.25'%3E%3C/rect%3E%3C/g%3E%3C/svg%3E";

const RequestResponseParameter = function () {
    var name = 'request_response_parameter';
    
    Blockly.Blocks[name] = {
        init: function(){
            this.jsonStructure = this.jsonStructure || {};
            this.appendDummyInput().appendField('','LABEL')
                .appendField(new Blockly.FieldImage(plusImage, 24, 24, "*", this.addKey.bind(this)),'PLUS')
                .setOnNewRow(true)
                .appendField(new Blockly.FieldImage(minusImage, 24, 24, "*",  this.deleteKey.bind(this)),'DELETE')
                .setOnNewRow(true);

            this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            var col = {};
            Object.assign(Blockly.Msg.CONNECTIONS_HUE,col);
            col.primary = '#b10069';
            this.setColour(col);
            this.setOutput(true,null);
            this.setTooltip('Get the value of an API response.');
            this.setHelpUrl(Blockly.BASE_HELP_URL+"#connections");
        },
        mutationToDom: function(){
            var path = this.inputList[0].fieldRow.map(function(x){
                if (x.name == 'PLUS' || x.name == 'DELETE') {
                    return '';
                } else {
                    return x.getValue();
                }
            }.bind(this));
            path = path.slice(1); // get rid of 'value of ' field
            path = path.filter(item => item);
            var mutation = Util.dom('mutation',{label:this.getFieldValue('LABEL'),path:path,parent_id:this.parent_id});
            // var jsonStructure = Util.dom('jsonStructure',{innerText:JSON.stringify(this.jsonStructure)});
            // mutation.appendChild(jsonStructure);
            return mutation;
        },
        domToMutation: function(xml){
            var label = (xml.getAttribute('label') || this.getFieldValue('LABEL'));
            this.setLabel(label);
            // var jsonStructure = (Array.prototype.slice.call(xml.children)).filter(function(x){return x.tagName.toLowerCase()==='jsonstructure'})[0] || {innerText:'{}'};

            //// this.jsonStructure = JSON.parse(xml.querySelector('jsonStructure').innerText)
            // this.jsonStructure = JSON.parse(jsonStructure.innerText || jsonStructure.textContent);
            this.path = xml.getAttribute('path');
            this.path = this.path?this.path.split(','):[];
            this.parent_id = xml.getAttribute('parent_id');
            //set jsonStructure if its parent still has it
            if (this.workspace.getBlockById(this.parent_id) != null) {
                let parentBlock = this.workspace.getBlockById(this.parent_id);
                if (parentBlock.type == 'rest_service' && parentBlock.responseJsonStructure!= null) {
                    this.jsonStructure = parentBlock.responseJsonStructure;
                    if (Object.keys(this.jsonStructure).length != 0) {
                        this.xmlToBlock(this.jsonStructure, this.path, 0);
                    }
                }
            }
            //create drop down with path if no jsonStructure set
            if (Object.keys(this.jsonStructure).length == 0) {
                this.createResponseDropdown(this.path);
            }
            // this.xmlToBlock(this.jsonStructure, this.path, 0);
        },
        createResponseDropdown: function (path) {
            this.inputList[0].removeField('PLUS')
            this.inputList[0].removeField('DELETE')
            for(let i=0; i < path.length; i++) {
                let ddOptions = [[path[i],path[i]]];
                let currentVal = ddOptions[0][0];
                let dd = new Blockly.FieldDropdown(ddOptions);
                dd.setValue(currentVal);
                this.inputList[0].appendField(dd, i);
            }
            this.inputList[0].appendField(new Blockly.FieldImage(plusImage, 24, 24, "*", this.addKey.bind(this)),'PLUS')
                .setOnNewRow(true)
                .appendField(new Blockly.FieldImage(minusImage, 24, 24, "*",  this.deleteKey.bind(this)),'DELETE')
                .setOnNewRow(true);
        },
        setLabel: function(label){
            this.getField('LABEL').setValue(label);
        },
        setParentId: function(id){
            this.parent_id = id;
        },
        setJsonStructure: function(json){
            this.jsonStructure = json;
        },

        addKey: function() {
            var json = this.jsonStructure;
            if (Object.keys(json).length == 0) return;
            this.inputList[0].removeField('PLUS')
            this.inputList[0].removeField('DELETE')
            var fieldList = this.inputList[0].fieldRow.slice(1); // exclude 'LABEL' 'PLUS' 'DELETE' field
            if (fieldList.length > 0) {
                for (var i = 0; i<fieldList.length; i++){
                    var path = fieldList[i].getValue();
                    json = json[path];
                }

                if(json!= null && typeof(json)=='object' && Object.keys(json).length){
                    var ddOptions = Object.keys(json).map(function(x){return [x,x]}.bind(this));
                    var currentVal = ddOptions[0][0];
                    var dd = new Blockly.FieldDropdown(function(){return ddOptions});
                    dd.setValue(currentVal);
                    dd.setOnChange(this.update.bind(this,fieldList.length));
                    this.inputList[0].appendField(dd,fieldList.length);
                }

            } else {
                if(json!= null && typeof(json)=='object' && Object.keys(json).length){
                    var _ddOptions = Object.keys(json).map(function(x){return [x,x]}.bind(this));
                    var _currentVal = _ddOptions[0][0];
                    var _dd = new Blockly.FieldDropdown(function(){return _ddOptions});
                    _dd.setValue(_currentVal);
                    _dd.setOnChange(this.update.bind(this,fieldList.length));
                    this.inputList[0].appendField(_dd,fieldList.length);
                }
            }
            this.inputList[0].appendField(new Blockly.FieldImage(plusImage, 24, 24, "*", this.addKey.bind(this)),'PLUS')
                .setOnNewRow(true)
                .appendField(new Blockly.FieldImage(minusImage, 24, 24, "*",  this.deleteKey.bind(this)),'DELETE')
                .setOnNewRow(true);
            this.getResult();

            // trigger onchange event for parent
            if (this.parentBlock_ && this.parentBlock_.changeHandler) 
                this.parentBlock_.changeHandler();
        },

        deleteKey: function() {
            var fieldList = this.inputList[0].fieldRow.slice(3); // exclude 'LABEL' 'PLUS' 'DELETE' field
            if (fieldList.length > 0) {
                this.inputList[0].removeField(fieldList.length-1);
                this.initSvg();
                this.render();
            }
            this.getResult();

            // trigger onchange event for parent
            if (this.parentBlock_ && this.parentBlock_.changeHandler) 
                this.parentBlock_.changeHandler();
        },

        update: function (level) {
            var fieldList = this.inputList[0].fieldRow.slice(3); // exclude 'LABEL' 'PLUS' 'DELETE' field
            for (var i = level+1; i<fieldList.length; i++){
                this.inputList[0].removeField(i);
                this.initSvg();
                this.render();
            }
            this.getResult();
        },

        getResult: function () {
            var fieldList = this.inputList[0].fieldRow.slice(3); // exclude 'LABEL' 'PLUS' 'DELETE' field
            var json = this.jsonStructure;
            for(var i = 0; i<fieldList.length; i++){
                var val = this.inputList[0].fieldRow[i+1].getValue();
                json = json[val];
            }

            if (typeof(json)!='object') {
                this.setOutput(true, typeof(json));
                switch (typeof(json)) {
                    case "boolean":
                        this.setOutputShape(Blockly.OUTPUT_SHAPE_HEXAGONAL);
                        this.setOutput(true, 'Boolean');
                        break
                    case "number":
                        this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
                        // fall through
                    case "string":
                        // fall through
                    default:
                        this.setOutputShape(Blockly.OUTPUT_SHAPE_SQUARE);
                        break
                }
            } else {
                if (Array.isArray(json)) {
                    this.setOutput(true, 'Array');
                } else {
                    this.setOutput(true, 'Object');
                }
                this.setOutputShape(Blockly.OUTPUT_SHAPE_ROUND);
            }

            this.initSvg();
            this.render();
        },

        xmlToBlock: function(json, path, depth){
            this.inputList[0].removeField('PLUS')
            this.inputList[0].removeField('DELETE')
            this.specToBlock(json, path, depth);
            this.inputList[0].appendField(new Blockly.FieldImage(plusImage, 24, 24, "*", this.addKey.bind(this)),'PLUS')
                .setOnNewRow(true)
                .appendField(new Blockly.FieldImage(minusImage, 24, 24, "*",  this.deleteKey.bind(this)),'DELETE')
                .setOnNewRow(true);

        },

        // json: structure at this depth
        // path: path through which to traverse json structure. can be undefined
        // depth: depth of json structure/dds, 0 = top level. so first dd = depth of 0
        specToBlock: function(json, path, depth){
            var moveAdd = false;
            var moveDelete = false;
            // necessary to clone path (otherwise .split(0,1) below gets confusing)
            path = [].concat(path) || [];
            // remove all fields greater than current depth (dd's to the right)
            var removes = [];
            var fieldList = this.inputList[0].fieldRow.slice(1); // exclude 'LABEL' field
            for (var i = 0; i<fieldList.length; i++){
                if (parseFloat(fieldList[i].name) >= depth){
                    removes.push(fieldList[i].name);
                }
                if (fieldList[i].name == "PLUS") {
                    this.inputList[0].removeField('PLUS');
                    moveAdd = true;
                }
                if (fieldList[i].name == "DELETE") {
                    this.inputList[0].removeField('DELETE');
                    moveDelete = true;
                }

            }

            removes.forEach(function(x){this.inputList[0].removeField(x)}.bind(this));
            // check if there should be another dd or maybe we hit a node/value;
            if(!!json && typeof(json)=='object' && Object.keys(json).length && !_.isEmpty(path)){
                var ddOptions = Object.keys(json).map(function(x){return [x,x]}.bind(this));
                var currentVal = path.splice(0,1)[0] || ddOptions[0][0];
                var dd = new Blockly.FieldDropdown(function(){return ddOptions},function(value){
                        this.specToBlock(json[value],path,depth+1)
                }.bind(this));
                dd.setValue(currentVal);
                this.inputList[0].appendField(dd, depth);
                this.specToBlock(json[currentVal],path,depth+1);
            }

            if (moveAdd) {
                this.inputList[0].appendField(new Blockly.FieldImage(plusImage, 24, 24, "*", this.addKey.bind(this)),'PLUS')
                    .setOnNewRow(true)
            }
            if (moveDelete) {
                this.inputList[0].appendField(new Blockly.FieldImage(minusImage, 24, 24, "*",  this.deleteKey.bind(this)),'DELETE')
                    .setOnNewRow(true);
            }
        }
    };

    Blockly.JavaScript[name] = function(block){
        /*
            This will produce the structure list (which will be used by other blocks) and
            a valid ES5 code, which chains all the selected parameters.

            Please note that we can't use ES6 optional chaining syntax (?.), so we need to 
            use old bulky syntax.

            So if the field list consists of "A", "B" and "C", then 

            The result structure will be:
            A|B|C

            The result code will be:
            (ResponseScope['ID'] && ResponseScope['ID']['A'] && ResponseScope['ID']['A']['B'] && ResponseScope['ID']['A']['B']['C']) ?? ""
        */
        var fieldList = block.inputList[0].fieldRow.slice(1, block.inputList[0].fieldRow.length-2); // exclude 'LABEL' 'PLUS' 'DELETE' field
        var structure = [];
        var path = `ResponseScope['${this.parent_id}']`;
        var code = path;
        for(var i = 0; i < fieldList.length; i++){
            var val = fieldList[i].getValue();
            path += `['${val}']`;
            code += ` && ${path}`;
            structure.push(val);
        }
        code = `/*STRUCTURE:${structure.join('|')}*/((${code}) ?? "")`;
        return [code, Blockly.JavaScript.ORDER_NONE]
    };

    // this should never show in the toolbox
    return null

};

export default RequestResponseParameter;
